<template>
  <div class="flex space-x-4" :class="[bgColorClasses, wrapperClasses]">
    <div class="flex flex-none items-center justify-center" :class="[iconBgColorClasses, iconWrapperClasses]">
      <slot name="icon" />
    </div>
    <div :class="contentWrapperClasses">
      <div class="flex-1" :class="{ 'space-y-2': inline }">
        <div class="flex items-center space-x-2">
          <div class="font-bold text-lg">{{ title }}</div>
          <cx-badge v-if="badgeText" :color="badgeColor">{{ badgeText }}</cx-badge>
        </div>
        <div class="text-gray-600">{{ helpText }}</div>
      </div>
      <div class="flex flex-none space-x-4">
        <slot name="buttons" v-bind="{ ctaButtonColor }" />
      </div>
    </div>
  </div>
</template>

<script>
import { CxBadge } from '~/components/cx';
import { DANGER, PRIMARY, WARN } from '~/components/cx/CxButton/CxButton.vue';

export const BILLING_BANNER_TYPES = {
  info: 'info',
  warn: 'warn',
  danger: 'danger',
};

export default {
  name: 'BillingBanner',

  components: { CxBadge },

  props: {
    badgeText: {
      default: '',
      type: String,
    },

    helpText: {
      required: true,
      type: String,
    },

    inline: {
      default: false,
      type: Boolean,
    },

    title: {
      required: true,
      type: String,
    },

    type: {
      default: BILLING_BANNER_TYPES.info,
      type: String,
    },
  },

  computed: {
    badgeColor() {
      switch (this.type) {
        case BILLING_BANNER_TYPES.warn:
          return 'yellow';
        default:
          return 'ghost';
      }
    },

    bgColorClasses() {
      switch (this.type) {
        case BILLING_BANNER_TYPES.danger:
          return 'bg-red-100 text-gray-900';
        case BILLING_BANNER_TYPES.warn:
          return 'bg-yellow-100 text-yellow-900';
        default:
          return 'bg-blue-100 text-blue-900';
      }
    },

    contentWrapperClasses() {
      return this.inline ? 'space-y-4' : 'flex items-center justify-between space-x-4 w-full';
    },

    ctaButtonColor() {
      switch (this.type) {
        case BILLING_BANNER_TYPES.danger:
          return DANGER;
        case BILLING_BANNER_TYPES.warn:
          return WARN;
        default:
          return PRIMARY;
      }
    },

    iconBgColorClasses() {
      if (this.inline) return '';

      switch (this.type) {
        case BILLING_BANNER_TYPES.danger:
          return 'bg-red-200';
        case BILLING_BANNER_TYPES.warn:
          return 'bg-yellow-200';
        default:
          return 'bg-blue-200';
      }
    },

    iconWrapperClasses() {
      return this.inline ? 'h-7 ' : 'h-14 rounded-full w-14';
    },

    wrapperClasses() {
      return this.inline ? 'p-4' : 'p-6';
    },
  },
};
</script>
